import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/order/pageList", formData);
}

function detail(id) {
  return request.get(constant.serverUrl + "/base/order/detail/"+id);
}

function goodsList(id) {
  return request.get(constant.serverUrl + "/base/order/goodsList/"+id);
}

function orderLog(id) {
  return request.get(constant.serverUrl + "/base/order/orderLog/"+id);
}

function updateOrder(formData) {
  return request.post(constant.serverUrl + "/base/order/updateOrder",formData);
}

//批量生成订单
function orderBatchGenerate(formData) {
  return request.post(constant.serverUrl + "/base/order/orderBatchGenerate",formData);
}

export default {
    pageList,detail,goodsList,orderLog,updateOrder,orderBatchGenerate
}