import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/merchantClient/orderMerchant/pageList", formData);
}


function getGoodsList(formData){
    return request.post(constant.serverUrl + "/merchantClient/orderMerchant/getGoodsList", formData);
}



export default {
    pageList,getGoodsList
}