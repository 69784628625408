<template>
    <div class="order-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">订单管理</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="订单编号" prop="outOrderNo">
                <el-input v-model="queryModel.outOrderNo" size="mini"/>
            </el-form-item>
            <el-form-item label="订单状态" prop="payStatus">
                <el-select v-model="queryModel.payStatus" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="商品选择" prop="goodsId">
                <el-select v-model="queryModel.goodsId" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in goodsList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="学校选择" prop="schoolId">
                <el-select v-model="queryModel.schoolId" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in schoolList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间" prop="createTimeRanges">
                <el-date-picker
                size="mini"
                v-model="queryModel.createTimeRanges"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
            
        </el-form>
        <el-divider></el-divider>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
        >
            <el-table-column prop="outOrderNo" label="订单编号" fixed="left"></el-table-column>            
            <el-table-column prop="name" label="学生姓名"></el-table-column>
            <el-table-column prop="schoolName" label="所属学校"></el-table-column>
            <el-table-column prop="goodName" label="商品信息"></el-table-column>
            <el-table-column prop="cost" label="实付金额"></el-table-column>
            <el-table-column prop="goodNum" label="商品数量"></el-table-column>
            <el-table-column prop="payName" label="支付方式"></el-table-column>    
            <el-table-column prop="shareFee" label="预计收益"></el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column label="订单状态" fixed="right" width="150">
                <template slot-scope="{row}">
                    <span v-if="row.payStatus=='20'">
                        已付款
                    </span>
                    <span v-else style="color:red">
                        已退款
                    </span>
                    <div>
                        <el-link type="primary"  @click="handleDetail(row)">详情</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <order-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></order-detail>

    </div>
</template>
<script>
import orderApi from '@/api/merchantClient/order'
import companyInfoApi from "@/api/base/companyInfo";
import orderDetail from "./order-detail";
export default {
    name:"merchantClientOrderList",
    data(){
        return{
            queryModel:{
                name:"",
                goodName:"",
                goodTypeId:"",
                schoolId:"",
                payStatus:"",
                outOrderNo:"",
                createTimeRanges:"",
                goodsId:"",
            },
            schoolList:[],
            goodTypeList:[{
                label:"校车服务",
                value:"1"
            },
            {
                label:"保险服务",
                value:"2"
            },
            {
                label:"虹膜",
                value:"3"
            },
            {
                label:"AI课程",
                value:"4"
            }],
            statusList:[{
                label:"已付款",
                value:"20"
            },{
                label:"已退款",
                value:"40"
            }],
            loading: false,
            tableData: [],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10,20,30],
            showModal: false,
            goodsList: []
        }
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("outOrderNo", this.queryModel.outOrderNo);
            formData.append("payStatus", this.queryModel.payStatus);
            formData.append("goodsId", this.queryModel.goodsId);
            formData.append("schoolId", this.queryModel.schoolId);
            formData.append("createTimeRanges", this.queryModel.createTimeRanges);



            orderApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;

            if (retObj.result) {
                var retData = retObj.data;

                this.changePage(this.pageIndex);

            }
        },
        handleDetail(record){
            this.modalTitle = "订单详情";
            this.operation = "detail";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        closeOrder(row){
            this.$confirm("是否确认关闭订单?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            })
            .then(() => {
                var formData = new FormData();

                formData.append("id", row.id);
                formData.append("payStatus", 30);

                orderApi.updateOrder(formData).then(response => {
                    var jsonData = response.data;

                    var count = jsonData.data;

                    if(count==1){
                        this.changePage(this.pageIndex)
                    }
                })
            })
        },
        offlineRefund(row,isOff){
            this.$confirm("是否确认退款?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
            .then(() => {
                var formData = new FormData();

                formData.append("id", row.id);
                formData.append("payStatus", 40);
                formData.append("isOff", isOff);

                orderApi.updateOrder(formData).then(response => {
                    var jsonData = response.data;

                    if (jsonData.result) {
                        var count = jsonData.data;

                        if(count==1){
                            this.changePage(this.pageIndex)
                        }
                    } else {
                        self.$message.error(jsonData.message + "");
                    }


                })
            })
        }
    },
    created() {
        var self = this;

         orderApi.getGoodsList().then(response => {
             var jsonData = response.data;
            this.goodsList = jsonData.data;
         });

        companyInfoApi.treeListOfSchoolByMerchantId().then(response => {
            var jsonData = response.data;
            this.schoolList = jsonData.data;
        });
    },
    mounted() {
        this.changePage(1);
    },
    components: {
        "order-detail": orderDetail
    },
}
</script>
<style scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}
.order-list{
    text-align: left;
}
</style>